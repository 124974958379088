import Seo from 'src/components/seo/Seo'
import ValeTroca from 'src/components/servicos/ServicesPage/ValeTroca'

function Page() {
  return (
    <>
      <Seo title="Vale troca | Decathlon" />
      <ValeTroca />
    </>
  )
}

export default Page
